<template>
  <div>
    <div class="print" style="font-size: 36px;">{{ $t("param.a38") }}
      <el-button v-print="'printObj'" type="primary" @click="printClick"
                 v-loading.fullscreen.lock="fullscreenLoading">{{ $t('common.a12') }}
      </el-button>
    </div>
    <!-- search -->
    <div class="flex mar30">
      <div v-if="quanxian == 'Admin' || quanxian == 'Config'" class="flex" style="width: 60%;">
        <div style="width: 89%;">
          <input v-model="search" type="text" class="search" :placeholder="$t('param.a26')">
        </div>
        <div class="search-btn" @click="getList"><i class="el-icon-search"></i></div>
      </div>

      <!--权限控制-->
      <div v-if="edit == 'false' || quanxian == 'Admin' || quanxian == 'Config'">
        <el-button size="large" type="primary" style="margin-right: 30px;" @click="centerDialogVisible = true">
          {{ $t('common.a1') }}
        </el-button>
        <el-button v-if="quanxian == 'Admin'" size="large" @click="deleteAll">{{ $t('common.a2') }}</el-button>
      </div>
    </div>
    <!--打印区域-->
    <div id="print_html" ref="print_html">
      <el-table
          :data="tableData"
          class="table mar30"
          border
          height="450px"
          @selection-change="checkSelect"
          style="width: 100%;">
        <el-table-column
            type="selection"
            width="60">
        </el-table-column>
        <el-table-column
            type="index"
            :label="$t('param.number')" sortable
            width="180">
        </el-table-column>
        <el-table-column
            prop="number" sortable
            :label="$t('param.a27')">
        </el-table-column>
      </el-table>
    </div>

    <div style="text-align: center;">
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pageNum"
          :page-size="pageSize"
          background
          layout="prev, pager, next"
          :total="total">
      </el-pagination>
    </div>

    <!-- button -->
    <div class="flex mar30 next-btn">
      <el-button size="large" v-show="edit != 'true'" type="primary" @click="back">{{ $t('common.a5') }}</el-button>
      <div v-show="edit == 'true'"></div>
      <el-button v-if="edit == 'false' && (quanxian == 'Admin' || quanxian == 'Config')" size="large" type="primary"
                 @click="finish(true)">{{ $t('common.a4') }}
      </el-button>
    </div>
    <el-dialog
        :title="$t('common.a1')"
        :visible.sync="centerDialogVisible"
        :close-on-click-modal="false"
        width="30%"
        center>
      <el-form label-position="right" :model="form">
        <el-form-item :label="$t('param.a27')">
          <el-input v-model="form.number" style="width: 300px" @input="inputCheck"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">{{ $t('common.a7') }}</el-button>
        <el-button :disabled="!form.number" type="primary" @click="save">{{ $t('common.a4') }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>

export default {
  data() {
    return {
      pid: '',
      pageNum: 1,
      pageSize: 10,
      total: 0,
      edit: false,
      search: '',
      tableData: [],
      centerDialogVisible: false,
      form: {
        number: '',
        id: ''
      },
      deleteList: [],
      saveState: false,//是否已保存成功
      quanxian: '',
      printObj: {
        id: 'print_html', //打印标签的id
      },
      fullscreenLoading: false,
    }
  },
  created() {
    let data = sessionStorage.getItem('file')
    if (data) {
      this.pid = JSON.parse(data).id;
    }
    this.edit = sessionStorage.getItem('edit');
    this.getList();

    let user = sessionStorage.getItem('user');
    if (user) this.quanxian = JSON.parse(user).quanxian;
  },
  beforeRouteLeave(to, from, next) {
    if (this.edit == 'true' || (
        to.path == '/Contacts' ||
        to.path == '/BasicParameters' ||
        to.path == '/Menus' ||
        to.path == '/Shortcutkeys' ||
        to.path == '/ForbiddenNumbers'
    )) {
      return next();
    }
    if (this.saveState && to.path == '/PhoneProfile') {
      return next();
    }
    this.$confirm(this.$t('message.a11'), this.$t('message.a10'), {
      confirmButtonText: this.$t('cp.a4'),
      cancelButtonText: this.$t('cp.a5'),
      type: 'warning'
    }).then(() => {
      if (this.pid) {
        this.postRequest('index/del_phone_profile', {ids: this.pid}, () => {
        })
      }
      next();
    }).catch(() => {
      next(false)
    });
  },
  methods: {
    printClick() {
      this.fullscreenLoading = true;
      this.html2canvas(this.$refs.print_html, {
        backgroundColor: null,
        useCORS: true,
        windowHeight: document.body.scrollHeight
      }).then((canvas) => {
        const url = canvas.toDataURL()
        this.printJS({
          printable: url,
          type: 'image',
          documentTitle: ''
        })
        this.fullscreenLoading = false;
      });
    },
    getList() {
      let params = {
        page: this.pageNum,
        page_size: this.pageSize,
        uid: sessionStorage.getItem('id'),
        pid: this.pid,

      }
      if (this.search) {
        params.number = this.search;
      }
      this.postRequest('index/forbidden_numbers_list', params, res => {
        this.tableData = res.data.info;
        this.total = res.data.count;
      })
    },
    save() {
      this.postRequest('index/add_forbidden_numbers', {
        uid: sessionStorage.getItem('id'),
        pid: this.pid,
        ...this.form
      }, () => {
        this.getList();
        this.form = {
          id: '',
          number: ''
        }
        this.centerDialogVisible = false
        this.finish()
      })
    },
    inputCheck() {
      this.form.number = this.form.number.replace(/[^0-9*+#]*/g, '').substr(0, 19)
    },

    checkSelect(e) {
      this.deleteList = e.map(v => v.id)
    },
    deleteAll() {
      if (this.deleteList.length == 0) return;
      // this.$confirm('是否删除选中的'+ this.deleteList.length+'条数据', this.$t('common.a2'), {
      this.$confirm(this.$t('common.a2') + '?', this.$t('message.a10'), {
        confirmButtonText: this.$t('cp.a4'),
        cancelButtonText: this.$t('cp.a5'),
        type: 'warning'
      }).then(() => {
        this.postRequest('index/del_forbidden_numbers', {ids: this.deleteList.join(',')}, () => {
          this.postRequest('index/create_xml', {
            id: this.pid,
            uid: sessionStorage.getItem('id')
          }, () => {
            this.$message({
              type: 'success',
              message: this.$t('message.a6')
            });
          })
          this.getList();
        })
      })
    },

    back() {
      this.$root.status = 'Shortcutkeys'
      this.$router.go(-1);
    },
    finish(push) {
      this.postRequest('index/create_xml', {
        id: this.pid,
        uid: sessionStorage.getItem('id')
      }, () => {
        this.$message({
          type: 'success',
          message: this.$t('message.a5')
        });
        if (this.edit != 'true' && push === true) {// 新增，并且是点击右下保存才跳转
          this.saveState = true;
          this.$root.status = 'PhoneProfile'
          this.$router.push('/PhoneProfile')
        }
      })
    },

    handleSizeChange(val) {
      this.pageSize = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      this.getList();
    }
  }
}
</script>

<style scoped>
.search {
  width: 100%;
  height: 46px;
  border: 3px solid #5D80F5;
  border-radius: 1px;
  font-size: 18px;
  text-indent: 20px;
  box-sizing: border-box;

}

.search:focus-visible {
  outline: none;
}

.search-btn {
  width: 11%;
  height: 46px;
  line-height: 46px;
  text-align: center;
  font-size: 22px;
  cursor: pointer;
  color: #C3D0FB;
  background: #5D80F5;
}

.flex {
  display: flex;
  justify-content: space-between;
}

.next-btn {
  margin-bottom: 30px;
}

.mar30 {
  margin-top: 30px;
}

.print {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
</style>
